import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger(
      'inOutAnimation', [
        transition(
          ':enter',
          [
            style({ 'height': 0, 'opacity': 0}),
            animate('1s ease-out', style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class HeaderComponent implements OnInit {

  public showMenu: boolean;

  constructor() {
    this.showMenu = false;
  }

  ngOnInit() {
  }

  toggleMenu() : void {
    this.showMenu = !this.showMenu;
  }

}
