import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public tipoServico: string;

  constructor() { }

  ngOnInit() {
    this.tipoServico = "consultoria";
  }

  faleComigo(tipoServico:string) : void {
    window.open('https://api.whatsapp.com/send?phone=558599305747&text=Oi Thaís,%20gostaria%20de%20mais%20informações%20sobre%20seus%20serviços%20de '+tipoServico+'.', '_newtab');
  }

}
